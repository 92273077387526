import React from 'react'
import PropTypes from 'prop-types'
import { Card, Grid, makeStyles, Typography } from '@material-ui/core'


const useStyles = makeStyles((theme) => ({
    statsValue: {
        textAlign: 'right',
        borderRightWidth: '4px',
        borderRightStyle: 'solid',
        borderRightColor: '#9E95A6',
        paddingRight: theme.spacing(2),
    },
    statsDescription: {
        paddingLeft: theme.spacing(2),
        fontSize: 13,
        color: '#A5A5A5',
        verticalAlign: 'middle',
    },
    statsAmount: {
        fontSize: 35,
        color: '#4E3F5E',
        fontWeight: 'bolder',
    },
    statsUnit: {
        fontSize: 20,
        color: theme.palette.primary.main,
        fontWeight: 'bold',
        verticalAlign: 'middle'
    },
    statsAbout: {
        fontSize: 14,
        color: '#A5A5A5',
        fontWeight: 'bold',
    },
    statContainer: {
        padding: theme.spacing(1)
    }
}));

const StatsComponent = props => {

    const classes = useStyles(props);
    const { data: { amount, unit, about, description } } = props;
    return (
        <Grid container direction="row" className={classes.statContainer} justify="center" alignItems="center">
            <Grid item xs={5} className={classes.statsValue}>
                <Typography className={classes.statsAmount}>
                    {amount}
                    <span className={classes.statsUnit}> {unit}</span>
                </Typography>
                <Typography className={classes.statsAbout}>
                    {about}
                </Typography>
            </Grid>
            <Grid item xs={6} >
                <Typography className={classes.statsDescription}>
                    {description}
                </Typography>
            </Grid>
        </Grid>
    )
}

StatsComponent.propTypes = {

}

export default StatsComponent
