import React from 'react'
import PropTypes from 'prop-types'
import { Grid, makeStyles } from '@material-ui/core'
import ClientsCarousal from '../../../../components/ClientsCarousal';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    root: {
        paddingTop: theme.spacing(30),
    },
    clientSectionheaders: {
        paddingBottom: theme.spacing(10)
    },

    sectionHeader: {
        fontSize: '30px',
        fontWeight: 'bolder',
        color: '#4E3F5E',
    },

}));

const ClientsContainer = props => {
    const classes = useStyles(props);

    return (
        <Grid container direction="row" justify="center" alignItems="center" className={classes.root}>
            <Grid item xs={11} md={10} className={classes.clientSectionheaders}>
                <Typography className={classes.sectionHeader} style={{ textAlign: 'center' }}>
                    What Our Student’s Say
                </Typography>
            </Grid>
            <ClientsCarousal />
        </Grid>
    )
}

ClientsContainer.propTypes = {

}

export default ClientsContainer
