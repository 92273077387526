import React from 'react'
import PropTypes from 'prop-types'
import { Grid, makeStyles, Typography } from '@material-ui/core'
import CallToActionButton from '../../../../components/CallToActionButton'
import StatsComponent from '../components/StatsComponent';

const useStyles = makeStyles((theme) => ({
    root: {
        paddingTop: theme.spacing(30)
    },
    sectionHeader: {
        fontSize: '30px',
        fontWeight: 'bolder',
        color: '#4E3F5E',
    },
    sectionSubHeader: {
        fontSize: '20px',
        fontWeight: 'bold',
        color: '#9E95A6',
        paddingTop: theme.spacing(5)
    },
    sectionContent: {
        fontSize: '14px',
        fontWeight: '400',
        color: '#9E95A6',
        paddingBottom: theme.spacing(2)

    },
    ctaContainer:{
        paddingBottom: theme.spacing(2)
    }
}));

const AboutUsContainer = props => {

    const classes = useStyles(props);

    const { setSelectedMenu } = props;
    const selectAboutMenu = () => {
        setSelectedMenu('about');
    }
    const stats = [
        { amount: 100, unit: '+', about: 'Registered Students', description: 'Makes us one of the leading training centers within India.' },
        { amount: 2, unit: '+', about: 'Years of Experience', description: 'Makes us young but be creative and poweful.' },
        { amount: 98, unit: '%', about: 'Success Stories', description: 'Speaks the success of out faculty and quality of curriculum.' },
        { amount: 100, unit: '%', about: 'Desired score', description: 'Is our desire and motifation for providing the best out there.' },
    ];
    return (
        <Grid container direction="row" justify="center" alignItems="center" className={classes.root}>
            <Grid item xs={9}>
                <Grid container direction="row" justify="center" alignItems="center" >
                    <Grid item xs={12} md={5}>
                        <Typography className={classes.sectionHeader} style={{ textAlign: 'center' }}>
                            Why Choose Us ?
                        </Typography>
                        <Typography className={classes.sectionSubHeader} style={{ textAlign: 'left' }}>
                            We ensure better education for a better world<br /><br />
                        </Typography>
                        <Typography className={classes.sectionContent} style={{ textAlign: 'justify' }}>
                            We have developed the most efficient set of strategies made with purpose of achieving targeted scores.<br /><br />

                            We are renowned for delivering successful services in coaching to assist our clients in establishing their bright educational as well as work career on the global platform.<br /><br />

                            Our commitment to excellence and unparalleled dedication of trainers to go the extra mile assist you to ensure full learning satisfaction.<br /><br />
                        </Typography>
                        <Grid container justify="flex-start" alignItems="center" className={classes.ctaContainer}>
                            <CallToActionButton actionType="secondary" text="Discover More About Us >>" onClick={selectAboutMenu} />
                        </Grid>
                    </Grid>
                    <div style={{ flexGrow: 1 }}></div>

                    <Grid item xs={12} md={5}>
                        <Grid item xs={12} md={12}>
                            <Grid container direction="row" justify="center" alignItems="center" >
                                {stats.map(stat => (<StatsComponent data={stat}/>))}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}

AboutUsContainer.propTypes = {

}

export default AboutUsContainer
