import React from 'react'
import PropTypes from 'prop-types'
import { Card, Grid, makeStyles, Typography } from '@material-ui/core'


const useStyles = makeStyles((theme) => ({
    cardHeaderText: {
        fontSize: '20px',
        fontWeight: 'bold',
        color: '#4E3F5E',
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(5)
    },
    card: {
        height: "100%",
        padding: theme.spacing(1),
        borderRadius: 10,
        boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.1)'
    },
    cardDescription: {
        fontSize: '10px',
        fontWeight: 'bold',
        color: '#555',
        textAlign: 'justify',
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(5)
    },
}));

const ContactCardItem = props => {

    const classes = useStyles(props);
    const { fieldName, icon, fieldValue} = props;
    const iconURl = `images/ieltsquad/pages/home/illustrations/services/${icon}.png`;
    return (
        <Grid item xs={10} md={4} lg={2}>
            <Card className={classes.card} >
                <Grid container direction="row" alignItems="left" layout={"row"}>
                    <div style={{ flexGrow: 1 }}></div>
                    <Grid item xs={10}>
                        <Grid container direction="column" className={classes.iconContainer} justify="center" alignItems="left">
                            <Grid item>
                                <Typography className={classes.cardHeaderText}>
                                    {fieldName}
                                </Typography>
                            </Grid>
                            <Grid item xs={10}>
                                <img src={iconURl} alt={icon} height={100} />
                            </Grid>

                        </Grid>
                    </Grid>
                    <div style={{ flexGrow: 1 }}></div>
                    <Grid item xs={12}>
                        <Grid container direction="column" className={classes.iconContainer} justify="center" alignItems="center">
                            <Grid item xs={10}>
                                <Typography className={classes.cardDescription}>{fieldValue}</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Card>
        </Grid>
    )
}

ContactCardItem.propTypes = {

}

export default ContactCardItem
