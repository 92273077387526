import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { Card, } from '@material-ui/core';
import NavbarButtons from './NavbarButtons';
import CallToActionButton from './CallToActionButton';

const useStyles = makeStyles((theme) => ({
    navbarCard: {
        shadow: 'none',
        padding: 10,
        // backgroundColor: '#a5a5a5'
    },
    root: {
        margin: 0,
        position: 'sticky',
        top: 0,
        backgroundColor: '#fff',
        zIndex: 1000
    }
}));

const CustomNavbarLarge = (props) => {
    const classes = useStyles();
    const { isMobile, menuItems, selectedMenu, setSelectedMenu,toggleContactModal } = props;
    return (
        isMobile ? (<></>) : (
            <Grid container className={classes.root}>
                <Grid item xs={12}>
                    <Grid container direction="row" justify="center" alignItems="center" className={classes.navbarCard}>
                        <Grid item xs={2}>
                            <Grid container direction="row" justify="flex-start" alignItems="center">
                                {/* <img src="images/logo.svg" alt="SNGC" /> */}
                            </Grid>
                        </Grid>

                        <Grid item xs={8} >
                            <Grid container direction="row" justify="center" alignItems="center">
                                {
                                    menuItems.map(menuItem => (
                                        <NavbarButtons
                                            isMobile={isMobile}
                                            key={menuItem.id}
                                            id={menuItem.id}
                                            text={menuItem.text}
                                            isSelected={selectedMenu === menuItem.id}
                                            setSelectedMenu={setSelectedMenu}
                                        />
                                    ))
                                }
                            </Grid>
                        </Grid>

                        <Grid item xs={2}>
                            <Grid container direction="row" justify="flex-end" alignItems="center">
                                {/* <CallToActionButton actionType="primary" text="GET IN TOUCH" onClick={toggleContactModal}/> */}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

            </Grid>
        )
    );
}

export default CustomNavbarLarge;