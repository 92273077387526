import React, { useState } from 'react';
import { Paper, Button, Grid, Typography, makeStyles } from '@material-ui/core'

import Carousel, { slidesToShowPlugin, arrowsPlugin, autoplayPlugin } from '@brainhubeu/react-carousel';
import '@brainhubeu/react-carousel/lib/style.css';
import ArrowBackIcon from '@material-ui/icons/ChevronLeft';
import ArrowForwardIcon from '@material-ui/icons/ChevronRight';
import { Avatar } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    studentPic: {
        width: theme.spacing(14),
        height: theme.spacing(14),
        backgroundColor: theme.palette.primary.main,
        margin: 'auto'
    },
    studentName: {
        fontSize: 20,
        color: theme.palette.primary.main,
        fontWeight: 'bold',
        textAlign: props => props.isMobile ? 'center' : 'left',
    },
    studentTestimonial: {
        fontSize: 14,
        color: theme.palette.text.secondary,
        fontWeight: '500',
        paddingTop: theme.spacing(1),
        textAlign: 'justify'
    }
}));

const ClientsCarousal = props => {
    const classes = useStyles(props);
    const student_testimonials = [
        {
            first_name: 'Jainy',
            last_name: 'Abraham',
            image: 'https://firebasestorage.googleapis.com/v0/b/ieltsquad-website.appspot.com/o/website%2Ffeatures%2Ftestimonials%2Fav3.jfif?alt=media&token=78ee2878-b9d4-4087-87c5-4ca12480a82e',
            testimonial: 'IELTSQUAD academy provides excellent coaching for IELTS examination. After joining here I could improve my language skills with the help of faculties over here. So I would recommend this institute for IELTS training.'
        },
        {
            first_name: 'John',
            last_name: 'Kurian',
            image: 'https://firebasestorage.googleapis.com/v0/b/ieltsquad-website.appspot.com/o/website%2Ffeatures%2Ftestimonials%2Fav2.jfif?alt=media&token=65995a1d-2b63-4776-8987-cf949ec76ff1',
            testimonial: 'Great place to learn IELTS! There are various techniques involved while approaching reading, listening, writing and speaking tests. We tried learning these aspects online which was time taking. '
        },
    ];

    const cutomLeftButton = (
        <Button color="primary" size="large" style={{ borderRadius: 10, boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.1)' }}><ArrowBackIcon /></Button>
    );
    const cutomRighttButton = (
        <Button color="primary" size="large" style={{ borderRadius: 10, boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.1)' }}><ArrowForwardIcon /></Button>
    );

    const arraowPluginConfig = {
        resolve: arrowsPlugin,
        options: {
            arrowLeft: cutomLeftButton,
            arrowLeftDisabled: cutomLeftButton,
            arrowRight: cutomRighttButton,
            arrowRightDisabled: cutomRighttButton,
            addArrowClickHandler: true,
        }
    };

    const autoPlayConfigs = {
        resolve: autoplayPlugin,
        options: {
            interval: 4000,
        }
    };
    return (

        <Grid xs={11} md={10}>

            <Carousel
                plugins={[
                    // 'arrows',
                    'infinite',
                    {
                        resolve: slidesToShowPlugin,
                        options: {
                            numberOfSlides: 1
                        }
                    },
                    { ...arraowPluginConfig },
                    { ...autoPlayConfigs }
                ]}
                breakpoints={{
                    640: {
                        plugins: [
                            // 'arrows',
                            'infinite',
                            {
                                resolve: slidesToShowPlugin,
                                options: {
                                    numberOfSlides: 1
                                }
                            },
                            // { ...arraowPluginConfig },
                            { ...autoPlayConfigs }
                        ]
                    },
                    900: {
                        plugins: [
                            // 'arrows',
                            'infinite',
                            {
                                resolve: slidesToShowPlugin,
                                options: {
                                    numberOfSlides: 1
                                }
                            },
                            // { ...arraowPluginConfig },
                            { ...autoPlayConfigs }
                        ]
                    },
                    1024: {
                        plugins: [
                            // 'arrows',
                            'infinite',
                            {
                                resolve: slidesToShowPlugin,
                                options: {
                                    numberOfSlides: 1
                                }
                            },
                            { ...arraowPluginConfig },
                            { ...autoPlayConfigs }
                        ]
                    },
                }}
            >

                {
                    student_testimonials.map(testimonialData => {
                        return <>
                            <Grid container direction="row" justify="center" alignItems="center">
                                <Grid item xs={8} md={6}>
                                    <Grid container direction="row" justify="center" alignItems="center">
                                        <Grid item xs={12} md={2}>
                                            <Avatar className={classes.studentPic} alt={testimonialData.first_name} src={testimonialData.image} ></Avatar>
                                        </Grid>
                                        <div style={{ flexGrow: 1 }} />
                                        <Grid item xs={12} md={9}>
                                            <Typography className={classes.studentName}>{testimonialData.first_name} {testimonialData.last_name}</Typography>
                                            <Typography className={classes.studentTestimonial}>{testimonialData.testimonial}</Typography>
                                        </Grid>
                                    </Grid>

                                </Grid>
                            </Grid>
                        </>
                    })
                }
            </Carousel>
        </Grid>
    )
}
export default ClientsCarousal
