import React from 'react'
import PropTypes from 'prop-types'
import { Card, Divider, Grid, makeStyles, Typography } from '@material-ui/core'
import ContactCardItem from '../../../../components/ContactCardItem'

const useStyles = makeStyles((theme) => ({
    root: {
        paddingTop: theme.spacing(14),
        flexGrow: 1
    },
    serviceCardsContainer: {
        paddingTop: theme.spacing(10),
        flexGrow: 1
    },
    sectionHeader: {
        fontSize: '30px',
        fontWeight: 'bolder',
        color: '#4E3F5E',
    },
}));

const ContactContainer = props => {
    const classes = useStyles(props);

    const { isMobile } = props;
    const cards = [
        { fieldName: 'Easy Tips', icon: 'EasyTips', fieldValue: <>Easy tips and strategies to crack the international language test</> },
        { fieldName: 'Personalized Coaching', icon: 'PersonalizedCoaching', fieldValue: <>Our teachers leave no stone unturned to polish you. Personal attention is given to all the students in all the modules.</> },
        { fieldName: 'Structural Speaking Sessions', icon: 'StructuralSpeakingSessions', fieldValue: <>Our well planned speaking sessions will help you to boost your confidence and performance.</> },
        { fieldName: 'Comprehensive Training', icon: 'ComprehensiveTraining', fieldValue: <>Our student centric approach will help you to achieve outstanding scores by pushing the capabilities beyond limits.</> },
        { fieldName: 'Group Discussion', icon: 'GroupDiscussion', fieldValue: <>Group discussion and group reading are additional interactive sessions to unleash your learning skills.</> },
    ]
    return (
        <Grid container direction="row" justify="center" alignItems="center" className={classes.root} >
            <Grid item xs={10}>
                <Typography className={classes.sectionHeader} style={{textAlign: 'center'}}>
                    Our Awesome Services
                </Typography>
            </Grid>
            <Grid item xs={11} className={classes.serviceCardsContainer}>
                <Grid container direction="row" justify="center" alignItems="start" spacing={3}>
                    {cards.map(card => {
                        const { fieldName, icon, fieldValue, hyperlink } = card
                        return (<ContactCardItem key={card.fieldName} fieldName={fieldName} icon={icon} fieldValue={fieldValue} hyperlink={hyperlink} />)
                    })}
                </Grid>
            </Grid>
        </Grid>
    )
}

ContactContainer.propTypes = {

}

export default ContactContainer
