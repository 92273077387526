import React from 'react'
import PropTypes from 'prop-types'
import { Grid, makeStyles, TextField, Typography } from '@material-ui/core'
import CallToActionButton from '../../../../components/CallToActionButton'
import StatsComponent from '../components/StatsComponent';
import { Avatar } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    root: {
        paddingTop: theme.spacing(30),
        paddingBottom: theme.spacing(25)
    },
    sectionHeader: {
        fontSize: '30px',
        fontWeight: 'bolder',
        color: '#4E3F5E',
    },
    sectionSubHeader: {
        fontSize: '14px',
        fontWeight: 'bold',
        color: '#9E95A6',
        paddingTop: theme.spacing(5)
    },
    sectionContent: {
        fontSize: '14px',
        fontWeight: '400',
        color: '#9E95A6',
        paddingBottom: theme.spacing(2)

    },
    ctaContainer: {
        paddingBottom: theme.spacing(2)
    },
    contactIcon: {
        width: theme.spacing(5),
        height: theme.spacing(5),
        margin: 'auto'
    },
    contactHeader: {
        fontSize: 15,
        color: theme.palette.text.primary,
        fontWeight: 'bold',
        textAlign: props => props.isMobile ? 'center' : 'left',
    },
    contactInfo: {
        fontSize: 12,
        color: theme.palette.text.secondary,
        fontWeight: '500',
        textAlign: 'justify'
    },
    contactCard: {
        paddingTop: theme.spacing(4)
    },
    sections:{
        paddingBottom: theme.spacing(4)
    }
}));

const GetInTouchContainer = props => {

    const classes = useStyles(props);
    const isMobile = props.isMobile;

    const { setSelectedMenu } = props;
    const selectAboutMenu = () => {
        setSelectedMenu('about');
    }
    const contactInfos = [
        { icon: 'images/ieltsquad/pages/home/illustrations/contact/email.svg', header: 'Mail Us', info: 'Registered Students' },
        { icon: 'images/ieltsquad/pages/home/illustrations/contact/phone.svg', header: 'Ring Us', info: 'Years of Experience' },
        { icon: 'images/ieltsquad/pages/home/illustrations/contact/map.svg', header: 'Find Us', info: 'Success Stories' },
    ];
    return (
        <Grid container direction="row" justify="center" alignItems="center" className={classes.root}>
            <Grid item xs={9}>
                <Grid container direction="row" justify="center" alignItems="center" >
                    <Grid item xs={12} md={5} className={classes.sections}>
                        <Typography className={classes.sectionHeader} style={{ textAlign: 'center' }}>
                            Have a Quick Query ?
                        </Typography>
                        <Typography className={classes.sectionSubHeader} style={{ textAlign: 'left' }}>
                            If you would like any further information on any of our course, please contact and we will respond as soon as possible...<br /><br />
                        </Typography>
                        <>{contactInfos.map(contact => (
                            <Grid container direction="row" justify="flex-start" alignItems="center" className={classes.contactCard} >
                                <Grid item xs={8} md={6}>
                                    <Grid container direction="row" justify="center" alignItems="center">
                                        <Grid item xs={12} md={2}>
                                            <img className={classes.contactIcon} alt={contact.header} src={contact.icon} />
                                        </Grid>
                                        <div style={{ flexGrow: 1 }} />
                                        <Grid item xs={12} md={9}>
                                            <Typography className={classes.contactHeader}>{contact.header}</Typography>
                                            <Typography className={classes.contactInfo}>{contact.info}</Typography>
                                        </Grid>
                                    </Grid>

                                </Grid>
                            </Grid>
                        ))}

                        </>
                    </Grid>
                    <div style={{ flexGrow: 1 }}></div>

                    <Grid item xs={12} md={5} className={classes.sections}>
                        <Grid item xs={12} md={12}>
                            <Grid container direction="row" justify="center" alignItems="center" >
                                <Typography className={classes.sectionHeader} style={{ textAlign: 'center' }}>
                                    We’d love to hear from you.
                                </Typography>
                                <Typography className={classes.sectionSubHeader} style={{ textAlign: 'left' }}>
                                    Commitment to excellence is our motto. Your Success begins here...<br /><br />
                                </Typography>
                                <Grid item xs={12} md={12}>
                                    <form className={classes.form} noValidate autoComplete="off">
                                        <Grid container direction="row" justify="flex-start" alignItems="flex-start" >
                                            <Grid item xs={12} md={5}>
                                                <TextField fullWidth variant="outlined" required id="lead_name" label="Name" /><br /><br />
                                                <TextField fullWidth variant="outlined" id="lead_email" label="Email" /><br /><br />
                                                <TextField fullWidth variant="outlined" required id="lead_phone" label="Phone" /><br /><br />
                                                {isMobile && (
                                                    <Grid item xs={12} md={6}>
                                                        <TextField fullWidth variant="outlined" multiline
                                                            rows={12} id="lead_message" label="Message" /><br /><br />
                                                    </Grid>
                                                )}
                                                <CallToActionButton
                                                    actionType="secondary"
                                                    text="Request A Callback >>>>>>>"
                                                    onClick={() => { }}
                                                />
                                            </Grid>
                                            <div style={{ flexGrow: 1 }} />
                                            {!isMobile && (
                                                <Grid item xs={12} md={6}>
                                                    <TextField fullWidth variant="outlined" multiline
                                                        rows={12} id="lead_message" label="Message" />
                                                </Grid>
                                            )}
                                        </Grid>
                                    </form>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}

GetInTouchContainer.propTypes = {

}

export default GetInTouchContainer
