import React from 'react'
import PropTypes from 'prop-types'
import { Fab, Grid, makeStyles, Typography } from '@material-ui/core';
import FacebookIcon from '@material-ui/icons/Facebook';
import TwitterIcon from '@material-ui/icons/Twitter';
import YouTubeIcon from '@material-ui/icons/YouTube';
import InstagramIcon from '@material-ui/icons/Instagram';
import MapIcon from '@material-ui/icons/Map';
import RingVolumeIcon from '@material-ui/icons/RingVolume';
import DraftsIcon from '@material-ui/icons/Drafts';
import { TextField } from '@material-ui/core';
import { InputAdornment } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    root: {
        paddingTop: theme.spacing(10),
        paddingBottom: props => props.isMobile ? theme.spacing(10) : theme.spacing(2),
        backgroundColor: '#fff',
        boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.15)'
    },
    fabButton: {
        margin: theme.spacing(1),
        opacity: 0.3
    },
    footerHeadings: {
        color: theme.palette.secondary.contrastText,
        textAlign: props => props.isMobile ? 'center' : 'left',
    },
    footerText: {
        color: theme.palette.secondary.contrastText,
    },
    textField: {
        color: theme.palette.secondary.contrastText,
        backgroundColor: theme.palette.secondary.light,
        borderRadius: 5,
    },
    credits: {
        color: theme.palette.text.secondary,
        fontWeight: 'bold'
    },
    devCompany: {
        color: "#fff",
    }

}));

const FooterContainer = props => {

    const classes = useStyles(props);

    return (
        <Grid container direction="row" justify="center" alignItems="center" className={classes.root}>
            {/* <Grid item xs={11}>
                <Grid container direction="row" justify="center" spacing={3} >
                    <Grid item xs={11} md={4} alignItems="center" justify="center">
                        <Typography variant="body1" className={classes.footerHeadings} style={{ textAlign: 'center' }}>SNGC <Typography variant="caption">: Beyond Expectations</Typography></Typography>
                        <br /><br />
                        <Grid container direction="column" justify="center" alignItems="center" spacing={4}>

                            <Grid container direction="row" justify="center" alignItems="center">
                                <img src="images/logo_dark.svg" />
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container direction="row" justify="center" alignItems="center" >
                                    <Fab size="small" aria-label="like" className={classes.fabButton} >
                                        <FacebookIcon />
                                    </Fab>
                                    <Fab size="small" aria-label="like" className={classes.fabButton} >
                                        <TwitterIcon />
                                    </Fab>
                                    <Fab size="small" aria-label="like" className={classes.fabButton} >
                                        <YouTubeIcon />
                                    </Fab>
                                    <Fab size="small" aria-label="like" className={classes.fabButton} >
                                        <InstagramIcon />
                                    </Fab>
                                </Grid>
                            </Grid>
                        </Grid>

                    </Grid>
                    <Grid item xs={11} md={4}>
                        <Typography variant="body1" className={classes.footerHeadings} >Contact Us</Typography>
                        <br />
                        <table>
                            <tbody>
                                <tr>
                                    <td valign="top">
                                        <MapIcon color="primary" fontSize="small" />
                                    </td>
                                    <td> </td>
                                    <td>
                                        <Typography variant="caption" className={classes.footerText}>
                                            SAIF NASSER<br />
                                            GENERAL MAINTENANCE LLC<br />
                                            P.O Box: 32646, Abu Dhabi, United Arab Emirates
                                        </Typography>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <RingVolumeIcon color="primary" fontSize="small" />
                                    </td>
                                    <td> </td>
                                    <td>
                                        <Typography variant="caption" className={classes.footerText}>
                                            971-506722440, 971-506163922
                                        </Typography>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <DraftsIcon color="primary" fontSize="small" />
                                    </td>
                                    <td> </td>
                                    <td>
                                        <Typography variant="caption" className={classes.footerText}>
                                            info@sngcuae.com
                                        </Typography>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </Grid>
                    <Grid item xs={11} md={4}>
                        <Typography variant="body1" className={classes.footerHeadings}>Subscribe</Typography><br />
                        <Typography variant="caption" className={classes.footerText}>Subscribe to our newsletter!<br />Stay in touch!</Typography><br /><br />

                        <TextField
                            size="small"
                            placeholder="Your Email"
                            id="outlined-start-adornment"
                            className={classes.textField}
                            InputProps={{
                                endAdornment: <InputAdornment position="end"><DraftsIcon color="primary" /></InputAdornment>,
                                style: { color: 'white' }
                            }}
                            variant="outlined"
                        /><br /><br />
                        <Typography variant="caption" className={classes.footerText}><small>* Don't worry, we don't spam</small></Typography><br /><br />
                    </Grid>
                </Grid> 
            </Grid><br />*/}
            <Grid item xs={9}>
                <hr />
            </Grid>
            <Grid item xs={11} style={{ textAlign: 'center' }}>
                <br />
                <Typography variant="caption" className={classes.credits}>
                    <Typography variant="caption" color="primary" style={{ fontWeight: 'bold' }}>ieltsquad </Typography>
                    © {new Date().getFullYear()}. All Rights Reserved.
                </Typography><br />
                <Typography variant="caption">
                    <small className={classes.devCompany}>by
                        <span> <embed src="images/AZ_Color.svg" width={12} ></embed></span>
                        <a href='http://akrdevtech.github.io/' style={{ textDecoration: 'none', color: '#2F4858' }}> akrdevtech</a>
                    </small>
                </Typography>
            </Grid>
        </Grid >
    )
}

FooterContainer.propTypes = {

}

export default FooterContainer
