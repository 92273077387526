import React from 'react'
import PropTypes from 'prop-types'
import { Grid, makeStyles } from '@material-ui/core'
import CustomHeading from '../../../../components/CustomHeading';
import CustomSubHeading from '../../../../components/CustomSubHeading';
import WorkCarousel from '../../../../components/WorkCarousel';
import WorkCarouselMob from '../../../../components/WorkCarouselMob';

const useStyles = makeStyles((theme) => ({
    root: {
        paddingTop: theme.spacing(15)
    },
    clientSectionheaders: {
        paddingBottom: theme.spacing(5)
    }

}));

const WorksContainer = props => {
    const classes = useStyles(props);
    const { isMobile } = props;

    return (
        <Grid container direction="row" justify="center" alignItems="center" className={classes.root}>
            <Grid item xs={11} md={10} className={classes.clientSectionheaders}>
                <CustomHeading text="OUR WORKS" />
                <br />
                <CustomSubHeading text="Witness The Quality Of Our Services" />
                <br />
            </Grid>
            {isMobile ? (<WorkCarouselMob />) : (<WorkCarousel />)}

        </Grid>
    )
}

WorksContainer.propTypes = {

}

export default WorksContainer
