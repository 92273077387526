import React from 'react'
import PropTypes from 'prop-types'
import { Card, Grid, makeStyles, Typography } from '@material-ui/core'
import CallToActionButton from '../../../../components/CallToActionButton';


const useStyles = makeStyles((theme) => ({
    bannerContainer: {
        // minHeight: '100%',
        backgroundColor: '#fff'
    },
    bannerContent: {
        position: 'absolute',
        left: 0, right: 0, top: '20%',
        zIndex: 1,
        boxShadow: 'none',
        backgroundColor: 'rgba(255,255,255,0)',
    },
    headingCaption: {
        color: '#292929',
        fontWeight: 'bolder',
        fontSize: "20px"
    },
    bannerMainTest: {
        fontWeight: 'bold',
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(3),
    },
    mobileBannerContainer: {

    }
}));

const BannerContainer = props => {
    const classes = useStyles(props);
    const { isMobile, toggleContactModal, setSelectedMenu } = props;
    const selectAboutMenu = () => {
        setSelectedMenu('about');
    }
    return (
        isMobile ? (
            <Grid container className={classes.mobileBannerContainer}>
                <img src="images/banner_mob.png" width="100%" z-index="-1" style={{ boxShadow: '0 0 20px 20px red inset' }} />
                <Card className={classes.bannerContent} >
                    <Grid container direction="row" justify="center" alignItems="center" className={classes.root}>
                        <Grid item xs={7}>
                            <Typography className={classes.headingCaption}>
                                Hi!!
                            </Typography>
                            <Typography variant="h4" className={classes.bannerMainTest}>
                                Lets Pave The Way <br />
                                For Your Success
                            </Typography>
                            <Typography variant="caption" className>
                                We offer a comprehensive educational experience for  <br />students to enhance their skills
                            </Typography><br /><br />
                            <CallToActionButton actionType="primary" text="Contact Us" onClick={toggleContactModal} />
                        </Grid>
                    </Grid>
                </Card>
            </Grid>
        ) : (
            <Grid container className={classes.bannerContainer}>
                <Grid container direction="row" justify="center" alignItems="center">
                    <div style={{ flexGrow: 1 }}></div>

                    <Grid item xs={4}>
                        <img src="images/ieltsquad/pages/home/backgrounds/carouselbg.png" width="90%" z-index="-1" />
                    </Grid>
                    <div style={{ flexGrow: 1 }}></div>
                    <Grid item xs={4}>
                        <img src="images/ieltsquad/pages/home/backgrounds/welcomeimage.png" width="90%" z-index="-1" />
                    </Grid>
                    <div style={{ flexGrow: 1 }}></div>

                </Grid>
                <Card className={classes.bannerContent} >
                    <Grid container direction="row" justify="center" alignItems="center" className={classes.root}>
                        <Grid item xs={7}>
                            <Typography className={classes.headingCaption}>
                                Hi!!
                            </Typography>
                            <Typography variant="h4" className={classes.bannerMainTest}>
                                Lets Pave The Way <br />
                                For Your Success
                            </Typography>
                            <Typography variant="caption" className>
                                We offer a comprehensive educational experience for  <br />students to enhance their skills
                            </Typography><br /><br />
                            <CallToActionButton actionType="primary" text="Contact Us" onClick={toggleContactModal} />
                        </Grid>
                    </Grid>
                </Card>
            </Grid >
        )
    )
}

BannerContainer.propTypes = {

}

export default BannerContainer
